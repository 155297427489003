import { configureStore } from "@reduxjs/toolkit";

import GSTListSlice from "./slices/GSTListSlice";
import GSTDetailsSlice from "./slices/GSTDetailsSlice";
import ImageUploadSlice from "./slices/ImageUploadSlice";
import SendGstImageSlice from "./slices/SendGstImageSlice";
import SendAlternateDetailsSlice from "./slices/SendAlternateDetailsSlice";
import SendTextImageSlice from "./slices/SendTextImageSlice";
import StateListSlice from "./slices/StateListSlice";
import DistrictListSlice from "./slices/DistrictListSlice";
import PincodeListSlice from "./slices/PincodeListSlice";
import ServiceAreaListSlice from "./slices/ServiceAreaListSlice";
import DealerListSlice from "./slices/DealerListSlice";
import SegmentSubCatgeoryListSlice from "./slices/SegmentSubCatgeoryListSlice";
import SegmentDetailsSlice from "./slices/SegmentDetailsSlice";
import BrandListSlice from "./slices/BrandListSlice";
import SegmentListSlice from "./slices/SegmentListSlice";
import FilterServiceAreaSlice from "./slices/FilterServiceArea";
import BrandViewSlice from "./slices/BrandListView";
import DistrictDealerListSlice from "./slices/DistDealerListSlice";
import ViewCategorySlice from "./slices/ViewCategoryListSlice";
import CategorySlice from "./slices/CategorySlice";
import CustomSpecSlice from "./slices/CustomSpecSlice";
import ViewProductPreviewSlice from "./slices/ViewProductPreviewSlice";
import ViewSkuPreviewSlice from "./slices/ViewSkuPreviewSlice";
import ViewMediaDetailsSlice from "./slices/ViewMediaDetails";
import ViewDescriptionDetailsSlice from "./slices/ViewDescriptionDeatils";
import ViewAttributesSlice from "./slices/ViewAttributesSlice";
import MetricsQuantitySlice from "./slices/MetricsQuantitySlice";
import loadCategorySlice from "./slices/loadCategorySlice";
import ProductListSlice from "./slices/ProductListSlice";
import ViewDetailedProductSlice from "./slices/ViewDetailedProduct";
import ViewDetailedSkuSlice from "./slices/ViewDetailedSku";
import summaryListSlice from "./slices/dealerCartSummarySlice";
import ShepherdListSlice from "./slices/ShepherdListSlice";


const store = configureStore({
        reducer: {
                GSTListSlice,
                GSTDetailsSlice,
                ImageUploadSlice,
                SendGstImageSlice,
                SendAlternateDetailsSlice,
                SendTextImageSlice,
                StateListSlice,
                DistrictListSlice,
                PincodeListSlice,
                ServiceAreaListSlice,
                DealerListSlice,
                SegmentDetailsSlice,
                SegmentSubCatgeoryListSlice,
                BrandListSlice,
                BrandViewSlice,
                SegmentListSlice,
                FilterServiceAreaSlice,
                DistrictDealerListSlice,
                ViewCategorySlice,
                CategorySlice,
                CustomSpecSlice,
                ViewProductPreviewSlice,
                ViewSkuPreviewSlice,
                ViewMediaDetailsSlice,
                ViewDescriptionDetailsSlice,
                ViewAttributesSlice,
                MetricsQuantitySlice,
                loadCategorySlice,
                ProductListSlice,
                ViewDetailedProductSlice,
                ViewDetailedSkuSlice,
                summaryListSlice,
                ShepherdListSlice
        },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;