import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface ShepherdState {
    data: any[]; // Define proper type based on your payload structure
  }
  
  const initialState: ShepherdState = {
    data: [],
  };

export const ShepherdListSlice = createSlice({
    name: "ShepherdJS",
    initialState: initialState,
    reducers: {
        setShepherdData: (state, actions: PayloadAction<any>) => {
            state.data = actions.payload
        },
    },
});

export const { setShepherdData } = ShepherdListSlice.actions
export default ShepherdListSlice.reducer